<template>
  <v-container>
    <!-- Add success dialog -->
    <v-dialog v-model="showSuccessDialog" max-width="400px">
      <v-card>
        <v-card-title>{{ $t('modal.reservationSuccessTitle') }}</v-card-title>
        <v-card-text>{{ $t('modal.reservationSuccessMessage') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" class="text-uppercase" @click="closeSuccessModal">{{ $t('buttons.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add error dialog -->
    <v-dialog v-model="showErrorDialog" max-width="400px">
      <v-card>
        <v-card-title>{{ $t('modal.reservationErrorTitle') }}</v-card-title>
        <v-card-text>{{ $t('modal.reservationErrorMessage') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" class="text-uppercase" @click="openWebsite">{{ $t('buttons.visitWebsite') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="organisation-wrapper">
      <v-col cols="12" lg="5" xl="4" v-if="$route.name === 'PublicReservationPage'">
        <organisation-info :organisationData="organisationData" />
      </v-col>
      <v-col
        cols="12"
        :lg="$route.name === 'PublicReservationPage' ? 7 : 12"
        :xl="$route.name === 'PublicReservationPage' ? 8 : 12">
        <!-- Stepper -->
        <validation-observer ref="drivingForm" v-slot="{ handleSubmit, failed }">
          <v-form lazy-validation @submit.prevent="handleSubmit(handleNextStep)">
            <v-stepper v-model="activeStep">
              <!-- Step 1 -->
              <v-stepper-step :complete="activeStep > 1" step="1"> {{ $t('driving.steps.mainInfo') }} </v-stepper-step>
              <v-stepper-content step="1">
                <basic-info
                  :editedItem="editedItem"
                  :drivingTypes="drivingTypes"
                  :organisationData="organisationData"
                  :failed="failed"
                  :formDisabled="formDisabled"
                  @selectFromAddress="selectFromAddress"
                  @selectFromAddressFocusout="selectFromAddressFocusout"
                  @selectToAddress="selectToAddress"
                  @selectToAddressFocusout="selectToAddressFocusout" />
              </v-stepper-content>

              <!-- Step 2 -->
              <v-stepper-step :complete="activeStep > 2" step="2">
                {{ $t('driving.vehicleClass') }}
              </v-stepper-step>
              <v-stepper-content step="2">
                <vehicle-class
                  :editedItem="editedItem"
                  :vehicleClasses="vehicleClasses"
                  :selectedVehicleClass="selectedVehicleClass"
                  :currencies="currencies"
                  :organisationDefaultCurrency="organisationDefaultCurrency"
                  :failed="failed"
                  :formDisabled="formDisabled"
                  @prevStep="prevStep"
                  @selectVehicleClass="selectVehicleClass"
                  @handleCurrencyChange="handleCurrencyChange" />
              </v-stepper-content>

              <!-- Step 3 -->
              <v-stepper-step :complete="activeStep > 3" step="3">
                {{ $t('driving.steps.additionalInfo') }}
              </v-stepper-step>
              <v-stepper-content step="3">
                <additional-info
                  :editedItem="editedItem"
                  :activeStep="activeStep"
                  :filesToSend="filesToSend"
                  :loading="loading"
                  :failed="failed"
                  :formDisabled="formDisabled"
                  @prevStep="prevStep"
                  @addNewFile="addNewFile"
                  @deleteNewFile="deleteNewFile" />
              </v-stepper-content>

              <!-- Step 4 -->
              <v-stepper-step :complete="activeStep > 4" step="4"> {{ $t('driving.steps.review') }} </v-stepper-step>
              <v-stepper-content step="4">
                <form-preview
                  :editedItem="editedItem"
                  :drivingTypes="drivingTypes"
                  :vehicleClasses="vehicleClasses"
                  :filesToSend="filesToSend"
                  :loading="loading"
                  :failed="failed"
                  :formDisabled="formDisabled"
                  @prevStep="prevStep" />
              </v-stepper-content>
            </v-stepper>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { formatDateTimeToDDMMHHmm } from '@/utils/formatDate';
import { defaultReservationDrivingItem } from '@/mixins/default-items';
import OrganisationInfo from '@/components/public-reservation-form/OrganisationInfo.vue';
import BasicInfo from '@/components/public-reservation-form/BasicInfo.vue';
import VehicleClass from '@/components/public-reservation-form/VehicleClass.vue';
import AdditionalInfo from '@/components/public-reservation-form/AdditionalInfo.vue';
import FormPreview from '@/components/public-reservation-form/FormPreview.vue';

export default {
  name: 'PublicReservationForm',
  components: {
    OrganisationInfo,
    BasicInfo,
    VehicleClass,
    AdditionalInfo,
    FormPreview,
  },
  data() {
    return {
      activeStep: 1, // Keeps track of the current active step
      steps: [{ title: 'Step 1' }, { title: 'Step 2' }, { title: 'Step 3' }, { title: 'Step 4' }],
      editedItem: {},
      filesToSend: [],
      loading: false,
      organisationData: {},
      vehicleClasses: [],
      currencies: [],
      showSuccessDialog: false,
      showErrorDialog: false,
      formDisabled: false,
      selectedVehicleClass: null, // Track the selected vehicle class
      organisationDefaultCurrency: null,
    };
  },
  created() {
    if (this.$route.query.param) {
      this.editedItem = {
        ...defaultReservationDrivingItem,
        public_booking_id: this.$route.query.param,
        driving_type_id: 1,
      };
      this.getPublicBookingData();
    } else {
      window.location.href = 'https://limoexpress.me/';
    }

    this.handleReceivedParameters(this.$route.query);

    this.handleLanguage();
  },
  computed: {
    drivingTypes() {
      return [
        { id: 1, name: this.$t('drivingOrderType.oneWayTransfer') },
        { id: 2, name: this.$t('drivingOrderType.fromAirport') },
        { id: 3, name: this.$t('drivingOrderType.toAirport') },
        { id: 4, name: this.$t('drivingOrderType.hourlyDaily') },
      ];
    },
  },
  methods: {
    handleNextStep() {
      switch (this.activeStep) {
        case 1:
          this.nextStep();
          break;
        case 2:
          this.nextStep();
          break;
        case 3:
          this.nextStep();
          break;
        case 4:
          this.preSubmitForm();
          break;
        default:
          break;
      }
    },
    nextStep() {
      if (this.activeStep < 4) {
        this.activeStep++;
      }
    },
    prevStep() {
      if (this.activeStep > 1) {
        this.activeStep--;
      }
    },
    async getPublicBookingData() {
      await this.$store
        .dispatch('drivings/getPublicBookingData', {
          id: this.editedItem.public_booking_id,
        })
        .then((res) => {
          this.organisationData = res.data.organisation;
          this.vehicleClasses = res.data.vehicleClasses.map((item) => {
            return { ...item, priceRelations: [] };
          });
          this.selectedVehicleClass = this.vehicleClasses[0];
          this.currencies = res.data.currencies;
          this.organisationDefaultCurrency = res.data.currencies.find((currency) => parseInt(currency.default) == 1).id;
        })
        .catch(() => {
          this.showErrorDialog = true;
          this.formDisabled = true;
        });
    },
    createFormData(token) {
      let formData = new FormData();

      // // todo nikola refactor -> ovo sam radio kako bi imali 4 stavke u listi ali na BE idu samo 2
      // if (this.editedItem.driving_type_id === 4) this.editedItem.driving_type_id = 2; // daily rent nije vise 2 vec 4
      // else this.editedItem.driving_type_id = 1; // sve ostalo postaje 1 - obican transfer

      formData.append('public_booking_id', this.editedItem.public_booking_id ? this.editedItem.public_booking_id : '');
      formData.append(
        'driving_type_id',
        this.editedItem.driving_type_id ? (this.editedItem.driving_type_id === 4 ? 2 : 1) : ''
      );
      formData.append(
        'pickup_time',
        this.editedItem.pickup_time ? formatDateTimeToDDMMHHmm(this.editedItem.pickup_time) : ''
      );
      formData.append('from_location', JSON.stringify(this.editedItem.from_location));
      formData.append(
        'from_location_name',
        this.editedItem.from_location_name ? this.editedItem.from_location_name : ''
      );
      [1, 2, 3].includes(this.editedItem.driving_type_id) &&
        formData.append('to_location', JSON.stringify(this.editedItem.to_location));
      formData.append('to_location_name', this.editedItem.to_location_name ? this.editedItem.to_location_name : '');
      formData.append('vehicle_class_id', this.editedItem.vehicle_class_id ? this.editedItem.vehicle_class_id : '');
      formData.append('currency_id', this.getCurrencyId(this.editedItem.vehicle_class_id));
      formData.append('price', this.getPrice(this.editedItem.vehicle_class_id));
      this.editedItem.driving_type_id === 4 &&
        formData.append(
          'num_of_waiting_hours',
          this.editedItem.num_of_waiting_hours ? this.editedItem.num_of_waiting_hours : ''
        );
      formData.append('full_name', this.editedItem.full_name ? this.editedItem.full_name : '');
      formData.append('phone_number', this.editedItem.phone_number ? this.editedItem.phone_number : '');
      formData.append('email', this.editedItem.email ? this.editedItem.email : '');
      formData.append('flight_number', this.editedItem.flight_number ? this.editedItem.flight_number : '');
      formData.append(
        'waiting_board_text',
        this.editedItem.waiting_board_text ? this.editedItem.waiting_board_text : ''
      );
      formData.append('suitcase_count', this.editedItem.suitcase_number);
      formData.append('passenger_number', this.editedItem.passenger_number);
      formData.append('baby_seat_count', this.editedItem.baby_seat_number);
      formData.append('stop_number', this.editedItem.stop_number);
      formData.append(
        'note_dispatcher',
        this.editedItem.note
          ? `${this.editedItem.note}${
              this.editedItem.wheelchair ? '; ' + i18n.t('driving.accessibleForWheelchair') : ''
            }`
          : this.editedItem.wheelchair
          ? i18n.t('driving.accessibleForWheelchair')
          : ''
      );
      this.filesToSend.map((item) => {
        formData.append('attachments[]', item);
      });
      const passengers = this.getPassengers();
      formData.append('passengers', JSON.stringify(passengers));
      formData.append('token', token);

      return formData;
    },
    async preSubmitForm() {
      try {
        // Call reCAPTCHA verification
        const token = await this.$recaptcha('public_reservation_form');
        // You can define any action name here

        // Submit your form or make an API call here with the token
        await this.submitForm(token);
      } catch {
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
      }
    },
    submitForm(token) {
      this.loading = true;
      let formData = this.createFormData(token);

      this.$store
        .dispatch('drivings/sendPublicReservation', formData)
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.sentSuccessfully'), color: 'green' });

          // Show success modal, reset form, and return to the first slide
          this.showSuccessDialog = true;
          this.resetForm(); // Call reset form function
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetForm() {
      // Reset form fields and stepper
      this.$refs.drivingForm.reset();
      this.activeStep = 1;
      this.editedItem = { ...defaultReservationDrivingItem, public_booking_id: this.$route.query.param };
    },
    getPassengers() {
      const fullName = this.editedItem.full_name.trim(); // Ensure there are no leading or trailing spaces

      // Split the full name into an array of words
      const nameParts = fullName.split(' ');

      // Assign the first part as the first name
      const firstName = nameParts[0];

      // Join the remaining parts as the last name (in case there are middle names)
      const lastName = nameParts.slice(1).join(' ');

      return [
        {
          first_name: firstName,
          last_name: lastName,
          phone: this.editedItem.phone_number,
          email: this.editedItem.email,
        },
      ];
    },
    closeSuccessModal() {
      this.showSuccessDialog = false;
    },
    selectFromAddressFocusout(input) {
      let value = input.target.value;
      if (
        value !== this.editedItem.from_location?.name &&
        value !== this.editedItem.from_location?.name + ` (${this.editedItem.from_location?.formatted_address})`
      ) {
        this.editedItem.from_location = { name: value };
        this.editedItem.from_location_name = value;
      }
    },
    selectToAddressFocusout(input) {
      let value = input.target.value;
      if (
        value !== this.editedItem.to_location?.name &&
        value !== this.editedItem.to_location?.name + ` (${this.editedItem.to_location?.formatted_address})`
      ) {
        this.editedItem.to_location = { name: value };
        this.editedItem.to_location_name = value;
      }
    },
    selectFromAddress(input) {
      this.editedItem.from_location = input;
      this.editedItem.from_location_name = input.name + ` (${input.formatted_address})`;
    },
    selectToAddress(input2) {
      this.editedItem.to_location = input2;
      this.editedItem.to_location_name = input2.name + ` (${input2.formatted_address})`;
    },
    addNewFile(item) {
      this.filesToSend = [...this.filesToSend, item];
    },
    deleteOldFile(index) {
      this.editedItem.attachments.splice(index, 1);
    },
    deleteNewFile(index) {
      this.filesToSend.splice(index, 1);
    },
    openWebsite() {
      window.open('https://limoexpress.me');
    },
    handleReceivedParameters(params) {
      // preselect driving type if we receive from and/or to location
      if (params.from && params.to) {
        this.editedItem.driving_type_id = 1;
      } else if (params.from) {
        this.editedItem.driving_type_id = 2;
      }
      params.from ? (this.editedItem.from_location = this.makeLocationObject(params.from)) : '';
      params.from ? (this.editedItem.from_location_name = params.from) : '';
      params.to ? (this.editedItem.to_location = this.makeLocationObject(params.to)) : '';
      params.to ? (this.editedItem.to_location_name = params.to) : '';
      params.pickup_time && this.isValidDateFormat(params.pickup_time)
        ? (this.editedItem.pickup_time = params.pickup_time)
        : '';
      params?.passenger_first_name && params?.passenger_last_name
        ? (this.editedItem.full_name = params?.passenger_first_name + ' ' + params?.passenger_last_name)
        : '';
      params.passenger_number ? (this.editedItem.phone_number = params.passenger_number) : '';
      params.passenger_email ? (this.editedItem.email = params.passenger_email) : '';
      params.note ? (this.editedItem.note = params.note) : '';
      params.flight_number ? (this.editedItem.flight_number = params.flight_number) : '';
      params.waiting_board_text ? (this.editedItem.waiting_board_text = params.waiting_board_text) : '';
      params.num_of_suit_cases ? (this.editedItem.suitcase_number = Number(params.num_of_suit_cases)) : '';
      params.num_of_passengers ? (this.editedItem.passenger_number = Number(params.num_of_passengers)) : '';
      params.num_of_baby_seats ? (this.editedItem.baby_seat_number = Number(params.num_of_baby_seats)) : '';
      params.num_of_stops ? (this.editedItem.stop_number = Number(params.num_of_stops)) : '';
    },
    handleLanguage() {
      const currentLanguage = localStorage.getItem('currentLanguage');
      const receivedLang = this.$route.query.language;

      if (receivedLang) {
        this.$store.dispatch('language/setLanguage', receivedLang);
        localStorage.setItem('currentLanguage', receivedLang);
      } else if (currentLanguage) {
        this.$store.dispatch('language/setLanguage', currentLanguage);
        localStorage.setItem('currentLanguage', currentLanguage);
      } else {
        this.$store.dispatch('language/setLanguage', 'en');
        localStorage.setItem('currentLanguage', 'en');
      }
    },

    makeLocationObject(location) {
      return {
        formatted_address: location,
        geometry: {
          location: { lat: null, lng: null },
          viewport: {
            south: null,
            west: null,
            north: null,
            east: null,
          },
        },
        name: location,
        place_id: null,
        url: null,
        html_attributions: [],
      };
    },

    isValidDateFormat(dateString) {
      // Define regex for the format dd-mm-yyyy hh:mm
      const regex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4} ([01][0-9]|2[0-3]):[0-5][0-9]$/;

      // Test if the dateString matches the regex
      return regex.test(dateString);
    },

    selectVehicleClass(item) {
      this.selectedVehicleClass = item;
    },

    async getVehicleClassPrices() {
      this.vehicleClasses = this.vehicleClasses.map((item) => {
        return { ...item, priceRelations: [] };
      });
      this.selectedVehicleClass = this.vehicleClasses[0];
      let data = null;
      if (
        JSON.parse(JSON.stringify(this.editedItem.from_location)).geometry.location.lat &&
        JSON.parse(JSON.stringify(this.editedItem.from_location)).geometry.location.lng
      ) {
        data = {
          public_booking_id: this.editedItem.public_booking_id,
          from_lat: JSON.parse(JSON.stringify(this.editedItem.from_location)).geometry.location.lat,
          from_lng: JSON.parse(JSON.stringify(this.editedItem.from_location)).geometry.location.lng,
        };
      }
      if (
        [1, 2, 3].includes(this.editedItem.driving_type_id) &&
        JSON.parse(JSON.stringify(this.editedItem.to_location)).geometry.location.lat &&
        JSON.parse(JSON.stringify(this.editedItem.to_location)).geometry.location.lng
      ) {
        data = {
          ...data,
          to_lat: JSON.parse(JSON.stringify(this.editedItem.to_location)).geometry.location.lat,
          to_lng: JSON.parse(JSON.stringify(this.editedItem.to_location)).geometry.location.lng,
        };
      }
      if (data !== null) {
        await this.$store
          .dispatch('drivings/getVehicleClassPrices', data)
          .then((res) => {
            this.vehicleClasses = this.vehicleClasses.map((vehicleClass) => {
              res?.data?.forEach((price) => {
                if (parseInt(vehicleClass.id) === parseInt(price.vehicle_class_id)) {
                  if ([1, 2, 3].includes(this.editedItem.driving_type_id)) {
                    vehicleClass.priceRelations.push({ currency: price.currency_id, price: price.price });
                  } else {
                    vehicleClass.priceRelations.push({
                      currency: price.currency_id,
                      price: price.price_per_hour * this.editedItem.num_of_waiting_hours,
                    });
                  }
                }
              });
              return vehicleClass;
            });
          })
          .then(() => {
            this.updateVehicleClassesPrices(this.organisationDefaultCurrency);
          })
          .catch(() => {
            this.$store.dispatch('showSnackbar', {
              text: i18n.t('snackbar.anErrorOccured'),
              color: 'red',
            });
          });
      }
    },
    handleCurrencyChange(currency) {
      if (currency != this.organisationDefaultCurrency) {
        this.updateVehicleClassesPrices(currency);
        this.organisationDefaultCurrency = currency;
      }
    },
    updateVehicleClassesPrices(currentCurrency) {
      this.vehicleClasses = this.vehicleClasses.map((vehicleClass) => {
        // Find the matching price relation for the current currency
        const matchingPriceRelation = vehicleClass.priceRelations.find(
          (priceRelation) => parseInt(priceRelation.currency) === currentCurrency
        );

        // Update the vehicleClass.price with the matching price if found, otherwise set it to null or a default
        vehicleClass.price = matchingPriceRelation
          ? { price: matchingPriceRelation.price, currency: this.getCurrency(matchingPriceRelation.currency) }
          : '/';

        return vehicleClass;
      });
    },

    getCurrency(currencyId) {
      return this.currencies.find((currency) => parseInt(currency.id) == currencyId);
    },

    getCurrencyId(vehicleClassId) {
      return this.vehicleClasses.find((vehicleClass) => vehicleClass.id == vehicleClassId)?.price?.currency?.id || '';
    },

    getPrice(vehicleClassId) {
      return this.vehicleClasses.find((vehicleClass) => vehicleClass.id == vehicleClassId)?.price?.price || '';
    },

    
  },
  watch: {
    activeStep(val, oldVal) {
      if (val === 2 && oldVal === 1) {
        this.getVehicleClassPrices();
      }
    },
    selectedVehicleClass: {
      handler() {
        this.editedItem.vehicle_class_id = this.selectedVehicleClass?.id;
      },
    },
  },
};
</script>

<style>
/* Optional: Add any additional styling here */
.organisation-wrapper {
  max-width: 1400px;
  margin: auto;
}

@media only screen and (max-width: 959.98px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: block;
    margin-left: 0.5rem;
  }
}
</style>
