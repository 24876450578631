<template>
  <v-text-field
    :value="value"
    :type="type"
    :label="label"
    class="d-flex align-center"
    prepend-icon="mdi-minus"
    append-outer-icon="mdi-plus"
    @click:append-outer="increment"
    @click:prepend="decrement"
    :disabled="disabled"
    :error-messages="errors"
    @input="$emit('input', $event)">
  </v-text-field>
</template>

<script>
export default {
  name: 'NumberInputField',
  props: ['value', 'type', 'label', 'disabled', 'errors'],
  methods: {
    increment() {
      if (this.errors.length === 0) {
        const newValue = Number(this.value) + 1;
        this.$emit('input', newValue); // Emit new value to parent
      }
    },
    decrement() {
      if (this.errors.length === 0) {
        if (this.value > 0) {
          const newValue = Number(this.value) - 1;
          this.$emit('input', newValue); // Emit new value to parent
        }
      }
    },
  },
};
</script>
