<template>
  <v-card>
    <v-card-title>{{ $t('driving.steps.mainInfo') }}</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <validation-provider rules="required" v-slot="{ errors }" name="driving_type">
              <v-autocomplete
                v-model="editedItem.driving_type_id"
                :items="drivingTypes"
                item-text="name"
                item-value="id"
                :label="$t('driving.drivingType')"
                :no-data-text="$t('select.noDataAvailable')"
                @focus="$event.target.click()"
                :disabled="formDisabled"
                :error-messages="errors"></v-autocomplete>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              ref="pickupTime"
              v-model="pickupTime"
              :close-on-content-click="false"
              :return-value.sync="editedItem.pickup_time"
              min-width="auto"
              offset-y
              transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <validation-provider rules="required" v-slot="{ errors }" name="pickup_time">
                  <v-text-field
                    v-model="editedItem.pickup_time"
                    clearable
                    :label="$t('driving.pickupTime')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :disabled="formDisabled"
                    :error-messages="errors"></v-text-field>
                </validation-provider>
              </template>
              <DatePicker
                v-model="editedItem.pickup_time"
                :minute-increment="1"
                :model-config="modelConfig"
                class="no-border"
                is24hr
                :min-date="new Date()"
                :first-day-of-week="firstDay"
                mode="dateTime">
              </DatePicker>
              <div class="date-picker-buttons">
                <v-btn class="primary cancel mr-2" elevation="2" text @click="pickupTime = false">
                  {{ $t('buttons.cancel') }}
                </v-btn>
                <v-btn class="primary mr-2" elevation="2" text @click="updatePickupTime">
                  {{ $t('buttons.confirm') }}
                </v-btn>
              </div>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6">
            <gmap-autocomplete
              :options="googleMapsOptions"
              :setFieldsTo="GoogleApiFields"
              :value="editedItem.from_location"
              selectFirstOnEnter
              class="gmap-autocomplete"
              @place_changed="(input) => $emit('selectFromAddress', input)">
              <template v-slot:default="slotProps">
                <validation-provider rules="required|max:200" v-slot="{ errors }" name="location_from">
                  <v-text-field
                    ref="input"
                    clearable
                    v-model="editedItem.from_location_name"
                    :label="$t('driving.locationFrom')"
                    @focusout="(input) => $emit('selectFromAddressFocusout', input)"
                    v-on:attrs="slotProps.attrs"
                    v-on:listeners="slotProps.listeners"
                    :disabled="formDisabled"
                    :error-messages="errors"
                    @keydown.enter.prevent
                    counter="200">
                  </v-text-field>
                </validation-provider>
              </template>
            </gmap-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" v-show="[1, 2, 3].includes(editedItem.driving_type_id)">
            <!-- first 3 are  regular transfer-->
            <gmap-autocomplete
              :options="googleMapsOptions"
              :setFieldsTo="GoogleApiFields"
              :value="editedItem.to_location"
              selectFirstOnEnter
              slot-ref-name="input2"
              @place_changed="(input) => $emit('selectToAddress', input)"
              class="gmap-autocomplete">
              <template v-slot:default="slotProps">
                <validation-provider rules="required|max:200" v-slot="{ errors }" name="location_to">
                  <v-text-field
                    ref="input2"
                    clearable
                    v-model="editedItem.to_location_name"
                    :label="$t('driving.locationTo')"
                    @focusout="(input) => $emit('selectToAddressFocusout', input)"
                    v-on:attrs="slotProps.attrs"
                    v-on:listeners="slotProps.listeners"
                    :disabled="formDisabled"
                    :error-messages="errors"
                    @keydown.enter.prevent
                    counter="200">
                  </v-text-field>
                </validation-provider>
              </template>
            </gmap-autocomplete>
          </v-col>
          <v-col cols="12">
            <google-map :editedItem="editedItem" :organisationData="organisationData" />
          </v-col>
          
          <v-col cols="12">
            <v-row>
              <v-col cols="12" sm="6" class="pt-0" v-show="editedItem.driving_type_id !== 4 && editedItem.distance">
                <div class="info-row">
                  <strong class="info-label">
                    {{ organisationData?.distance_unit == 'mi' ? $t('driving.distanceMi') : $t('driving.distance') }}:
                  </strong>
                  <strong class="info-value">{{ editedItem.distance }}</strong>
                </div>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0" v-show="editedItem.driving_type_id !== 4 && editedItem.duration">
                <div class="info-row">
                  <strong class="info-label"> {{ $t('driving.expectedTime') }}: </strong>
                  <strong class="info-value">{{ editedItem.duration }}</strong>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" v-if="editedItem.driving_type_id == 4">
            <validation-provider
              rules="required|numeric|max:3|min_value:1|max_value:100"
              v-slot="{ errors }"
              name="number_of_hours">
              <v-text-field
                v-model="editedItem.num_of_waiting_hours"
                clearable
                :label="$t('driving.numberOfHours')"
                type="text"
                counter="3"
                :disabled="formDisabled"
                :error-messages="errors"></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6">
            <validation-provider rules="required|max:200" v-slot="{ errors }" name="full_name">
              <v-text-field
                v-model="editedItem.full_name"
                name="full_name"
                clearable
                counter="200"
                :label="$t('driving.fullName')"
                :disabled="formDisabled"
                :error-messages="errors"></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6">
            <validation-provider
              :rules="{
                required: true,
                max: 200,
                regex: /^\+?[0-9\s\-()]+$/,
              }"
              v-slot="{ errors }"
              name="phone_number">
              <v-text-field
                v-model="editedItem.phone_number"
                name="phone"
                clearable
                counter="200"
                :label="$t('driving.phoneNumber')"
                :disabled="formDisabled"
                :error-messages="errors"></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6">
            <validation-provider rules="required|email|max:200" v-slot="{ errors }" name="email" ref="emailRef">
              <v-text-field
                v-model="editedItem.email"
                name="email"
                clearable
                counter="200"
                :label="$t('driving.email')"
                :disabled="formDisabled"
                :error-messages="errors"></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <button-submit :failed="failed || formDisabled" buttonText="buttons.next"></button-submit>
    </v-card-actions>
  </v-card>
</template>

<script>
import store from '@/store';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { DatePicker } from 'v-calendar/src/components';
import GoogleMap from '@/components/public-reservation-form/GoogleMap.vue';

export default {
  name: 'BasicInfo',
  props: ['editedItem', 'drivingTypes', 'organisationData', 'failed', 'formDisabled'],
  components: {
    ButtonSubmit,
    DatePicker,
    GoogleMap,
  },
  data() {
    return {
      pickupTime: null,
      googleMapsOptions: {
        bounds: {
          north: 45.830329,
          south: 41.51274,
          east: 20.834725,
          west: 16.332208,
        },
        // componentRestrictions: {country: ["me", "hr", "rs", "ba", "al"]},
        strictBounds: false,
      },
      GoogleApiFields: ['name', 'formatted_address', 'geometry', 'place_id', 'plus_code', 'url'],
      modelConfig: {
        type: 'string',
        mask:
          store.getters['auth/user']?.organisation?.organisation_settings?.date_format == 'mm-dd'
            ? 'MM-DD-YYYY HH:mm'
            : 'DD-MM-YYYY HH:mm',
      },
    };
  },
  created() {},
  methods: {
    updatePickupTime() {
      this.$refs.pickupTime.save(this.editedItem.pickup_time);
    },
  },
  watch: {},
};
</script>

<style></style>
