<template>
  <GmapMap ref="map" :center="center" :zoom="9" map-type-id="roadmap" style="width: 100%; height: 300px">
    <GmapMarker
      :key="'transfer' + index"
      v-for="(item, index) in filteredMarkers"
      :position="item"
      :clickable="true"
      :draggable="false"
      :icon="generateMapIcon(item)" />
  </GmapMap>
</template>

<script>
import i18n from '@/i18n/i18n';
import { formatTimeFromSeconds } from '@/utils/formatDate';
import { getGoogleMapsAPI } from 'gmap-vue';

export default {
  name: 'GoogleMap',
  props: ['editedItem', 'organisationData'],
  data() {
    return {
      center: { lat: 1.1, lng: 1.1 }, // Initial map center
      markers: [
        { lat: null, lng: null }, // From location
        { lat: null, lng: null }, // To location
      ],
      google: null,
      directionsService: null, // Directions service instance
      directionsRenderer: null, // Directions renderer instance
    };
  },
  computed: {
    filteredMarkers() {
      // Filter markers with valid lat/lng
      return this.markers.filter((marker) => marker.lat !== null && marker.lng !== null);
    },
  },
  created() {
    this.loadGoogleMaps();
  },
  methods: {
    async waitForGoogleMapsAPI() {
      return new Promise((resolve) => {
        const checkExist = setInterval(() => {
          if (window.google && window.google.maps) {
            clearInterval(checkExist);
            resolve();
          }
        }, 100);
      });
    },

    async loadGoogleMaps() {
      try {
        await this.waitForGoogleMapsAPI();
        this.google = await getGoogleMapsAPI();
        this.directionsService = new this.google.maps.DirectionsService();
        this.directionsRenderer = new this.google.maps.DirectionsRenderer({
          map: this.$refs.map.$mapObject, // Attach renderer to the map
          suppressMarkers: true, // Prevent default markers
        });
      } catch (error) {
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
      }
    },

    updateMapPins(location, pinType) {
      if (location?.geometry?.location) {
        const { lat, lng } = JSON.parse(JSON.stringify(location.geometry.location));

        if (pinType === 'from') {
          this.setMarker(0, lat, lng);
        } else {
          this.setMarker(1, lat, lng);
        }

        // Center and adjust zoom to fit all markers
        this.fitMapToMarkers();

        // Draw the path if both markers are set
        if (this.markers[0].lat && this.markers[1].lat) {
          this.drawPath();
        }
      } else {
        if (pinType === 'from') {
          this.setMarker(0, null, null);
        } else {
          this.setMarker(1, null, null);
        }

        this.removePath();
      }
    },

    setMarker(marker, lat, lng) {
      this.markers[marker].lat = lat;
      this.markers[marker].lng = lng;
    },

    fitMapToMarkers() {
      if (this.google && this.google.maps && this.$refs.map?.$mapObject) {
        const bounds = new this.google.maps.LatLngBounds();

        // Extend bounds to include all valid markers
        this.filteredMarkers.forEach((marker) => {
          bounds.extend(new this.google.maps.LatLng(marker.lat, marker.lng));
        });

        if (!bounds.isEmpty()) {
          this.$refs.map.$mapObject.fitBounds(bounds);
        }
      }
    },

    drawPath() {
      if (!this.directionsService || !this.directionsRenderer) {
        return;
      }

      const origin = new this.google.maps.LatLng(this.markers[0].lat, this.markers[0].lng);
      const destination = new this.google.maps.LatLng(this.markers[1].lat, this.markers[1].lng);

      this.directionsService.route(
        {
          origin,
          destination,
          travelMode: this.google.maps.TravelMode.DRIVING, // Options: DRIVING, WALKING, BICYCLING, TRANSIT
        },
        (response, status) => {
          if (status === this.google.maps.DirectionsStatus.OK) {
            this.directionsRenderer.setDirections(response);

            // Extract distance and duration
            const route = response.routes[0];
            const leg = route.legs[0]; // First leg of the route
            this.editedItem.distance = this.calculateDistance(leg.distance.value); // in m
            this.editedItem.duration = formatTimeFromSeconds(leg.duration.value); // in s
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        }
      );
    },

    calculateDistance(distance) {
      if (this.organisationData.distance_unit === 'mi') {
        return (distance / 1609.34).toFixed(1); // Convert to miles and round to 1 decimal place
      } else {
        return (distance / 1000).toFixed(1); // Convert to kilometers and round to 1 decimal place
      }
    },

    removePath() {
      if (this.directionsRenderer) {
        this.directionsRenderer.setDirections({ routes: [] }); // Clear the directions
      }
    },

    generateMapIcon() {
      // Generate pin icon with a color based on transfer or driver status
      let color = '#007D00';

      const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path fill="${color}" d="M12,6.5A3.5,3.5 0 0,1 15.5,10A3.5,3.5 0 0,1 12,13.5A3.5,3.5 0 0,1 8.5,10A3.5,3.5 0 0,1 12,6.5M12,2A8,8 0 0,0 4,10C4,16 12,22 12,22C12,22 20,16 20,10A8,8 0 0,0 12,2Z" /></svg>`;
      return `data:image/svg+xml;base64,${btoa(svg)}`;
    },
  },
  watch: {
    'editedItem.from_location': {
      handler() {
        this.updateMapPins(this.editedItem.from_location, 'from');
      },
    },
    'editedItem.to_location': {
      handler() {
        this.updateMapPins(this.editedItem.to_location, 'to');
      },
    },
    'editedItem.driving_type_id'(val, oldVal) {
      if ([1, 2, 3].includes(oldVal) && val === 4) {
        this.setMarker(1, null, null);
        this.removePath();
      } else if ([1, 2, 3].includes(val) && oldVal === 4) {
        this.updateMapPins(this.editedItem.from_location, 'from');
        this.updateMapPins(this.editedItem.to_location, 'to');
      }
    },
  },
};
</script>
